@import "base";
@import "animated";
@import "bordered-pulled";
@import "core";
@import "fixed-width";
@import "icons";
@import "larger";
@import "list";
@import "mixins";
@import "path";
@import "rotated-flipped";
@import "screen-reader";
@import "stacked";
@import "variables";
@import url("https://fonts.googleapis.com/css2?family=Pixelify+Sans&display=swap");



body {
  background-color: $background;
  color: $text;
  
}


#application {
  display: flex;
  flex-direction: column;
  //border: 2px solid red;
  overflow: hidden;
  width: 100vw;
  height: auto;
  -webkit-display: flex;
  -webkit-flex-direction: column;


  #homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: auto;
    -webkit-display: flex;
    -webkit-flex-direction: column;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  z-index: 1000;
  transition: top 0.3s;

  &.hide {
    top: -150px;
  }
}

#menu {
  color: $text;
  opacity: 0.7;
  width: 100%;
  height: auto;
  position: fixed;
  font-family: $font-fam;
  font-size: 1em;
  font-weight: bold;
  z-index: 100;
  //border-bottom: 3px solid rgb(174, 179, 203);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  //border: 2px solid red;

  @media (max-width: $xxlarge) {
    font-size: 1em;
  }

  @media (max-width: $xlarge) {
    font-size: 0.8em;
  }

  @media (max-width: $large) {
    font-size: 0.8em;
  }

  @media (max-width: $medium) {
    font-size: 0.6em;
  }
  @media (max-width: $small) {
    top: 0;
    background-color: transparent;
  }

  &:hover {
    opacity: 1;
  }

  .shown {
    opacity: 0.7;
  }

  #menu-container {
    width: 100%;
    background-color: rgb(67, 67, 67);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 0.5s ease-in-out;
    //border: 2px solid red;

    @media (max-width: $small) {
      background-color: transparent;
    }
    

    ul {
      width: 70%;
      height: 100px;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0;
      //border: 2px solid red;

      @media (max-width: $small) {
        display: none;
      }

      li {
        height: 100%;
        width: 100%;
        //margin: 0 2%;
        font-size: 1.5em;
        font-weight: bold;
        transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out,
          height 0.2s ease-in-out, font-size 0.2s ease-in-out,
          border-radius 0.2s ease-in-out, border 0.2s ease-in-out,
          color 0.2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        //border: 2px solid yellow;

        svg {
          width: 20px;
          height: 100%;
          margin-right: 10px;
          cursor: pointer;
          //border: 2px solid red;
          transform: translateY(2px);
          @media (max-width: $medium) {
            width: 15px;
          }

          path {
            fill: rgb(255, 255, 255);
          }
        }

        &:hover {
          font-size: 1.5em;
          background-color: orange;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10%;

          a {
            color: white;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        a {
          color: white;
          text-decoration: none;
          //border: 2px solid red;

          &:hover {
            color: white;
            text-decoration: none;
          }
        }
      }

      img {
        height: 100px;
        width: auto;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 20%;
      }
    }
    #menu-bars {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      display: none;
      //z-index: 100;
      //border: 2px solid rgb(183, 183, 183);

      @media (max-width: $small) {
        display: flex;
        justify-content: center;
        margin-top: 5px;
      }

      svg {
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        position: fixed;
        margin-bottom: 10px;
        padding: 5px;
        //background-color: rgb(67, 67, 67);

        path {
          fill: rgb(255, 255, 255);
        }
      }
    }

    #hidden-menu {
      width: 100%;
      height: 100%;
      background-color: rgba(67, 67, 67, 0.8);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      //border: 3px solid orange;

      @media (max-width: $xxlarge) {
        display: none;
      }

      @media (max-width: $small) {
        display: flex;
      }

      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //align-items: center;
        width: 80%;
        height: 100%;
        border-left: 2px solid rgb(255, 255, 255);

        &:hover {
          border-left:2px solid orange;
        }

        #social-media-menu{
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: right;
          align-items: center;
          position: absolute;
          bottom: 10%;
          left: 0%;
          //border: 2px solid red;

          a{
            margin: 10px 10px 0px 10px;
            display: flex;
            justify-content: right;
            align-items: center;
            width: 50px;
            svg{
              width: 35px;
              height: auto;
              //margin: 10px;
              //border: 2px solid red;

              path{
                fill: white;
              }
            }
          }
          
        }

        #moon-logo-menu{
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          position: absolute;
          top: 0%;
          left: 40%;
          //border: 2px solid red;

          img{
            width: 50%;
            height: auto;
            //border: 2px solid red;
          }
        }

        li {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: right;
          align-items: center;
          font-size: 2.8em;
          font-weight: bold;
          opacity: 1;
          margin-top: 20px;
          margin-right: 40px;
          margin-bottom: 10%;
          //margin-left: 20px;
          //background-color: rgba(255, 255, 255, 0.9);
          //border: 2px solid grey;

          &:hover {
            //background-color: orange;
            border-radius: 0%;
            a {
              color: orange;
            }
            path {
              fill: orange;
            }
          }

          svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            padding: 0;
            cursor: pointer;
            transform: translateY(2px);
            align-items: right;

            path {
              fill: orange;
            }
          }

          a {
            color: white;
          }

          //border: 2px solid red;
          transition: background-color 2s ease-in-out, width 0.2s ease-in-out,
            height 2s ease-in-out, font-size 2s ease-in-out,
            border-radius 2s ease-in-out, border 2s ease-in-out,
            color 2s ease-in-out opacity 2s ease-in-out;
        }
      }
    }
  }
}

#hero {
  width: 90vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $text;
  font-family: $font-fam;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  //border: 6px dotted red;

  @media (max-width: 4000px) {
    margin-bottom: 5%;
  }

  @media (max-width: $xxlarge) {
    margin-top: 0px;
    margin-bottom: 5%;
  }

  @media (max-width: $large) {
    margin-bottom: -100px;
  }

  @media (max-width: $small) {
    height: auto;
    //border: 6px solid yellow;
    margin-bottom: 0;
    z-index: 1;
  }

  #banner-about {
    width: 100vw;
    height: auto;
    //border-top: 2px solid white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    //overflow: hidden;

    @media (max-width: $small) {
      width: 100vw;
    }

    img {
      width: 100%;
      height: auto;
      //border: 2px solid red;
      display: block;
    }

    &::after {
      content: "";
      background: linear-gradient(
        to bottom,
        rgba($background, 1) 0%,
        transparent 50%,
        rgba($background, 1) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

  #hero-container {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin-bottom: 0;
    position: relative;
    top: 0;
    transform: translateY(100px);

    z-index: 1;
    @media (max-width: $xxlarge) {
      width: 100vw;
    }

    @media (max-width: $small) {
      transform: translateY(0px);
    }

    #hero-image {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      //position: relative;
      //border: 2px solid blue;

      @media (max-width: $xxlarge) {
        width: 80vw;
      }

      @media (max-width: $xlarge) {
        width: 80vw;
      }
      @media (max-width: $medium) {
        width: 80vw;
      }

      @media (max-width: $small) {
        width: 80vw;
        flex-direction: column;
      }
      @media (max-width: $xsmall) {
        width: 80vw;
        flex-direction: column;
      }

      .hero-picture {
        flex: 1;
        width: 60%;
        height: 100%;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        //position: relative;
      }

      #hero-godzilla {
        @media (max-width: $small) {
          width: 100%;
          height: auto;
        }
      }
      #hero-jaime {
        @media (max-width: $small) {
          width: 100%;
          height: auto;
        }

        @media (max-width: $small) {
          transform: translateY(-50px);
        }
      }
    }

    #hero-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      z-index: 1;
      margin-bottom: -50px;
      position: relative;
      //border: 8px solid orange;

      @media (max-width: $large) {
        margin-bottom: 0;
      }

      @media (max-width: $medium) {
        margin-bottom: -30px;
      }
      @media (max-width: $small) {
        margin-top: 80px;
        margin-bottom: -20px;
      }

      h1 {
        font-size: 3.5em;
        font-weight: normal;
        background-color: rgba(black, 0);
        color: white;
        margin: 0;

        @media (max-width: $medium) {
          font-size: 3em;
        }
        @media (max-width: $small) {
          font-size: 1.5em;
        }
      }

      h2 {
        width: 100%;
        font-family: $courier-text;
        font-size: 1em;
        font-weight: normal;
        background-color: rgba(black, 0);
        color: orange;
        margin-top: -15px;
        z-index: 0;

        @media (max-width: $medium) {
          font-size: 1em;
        }
        @media (max-width: $small) {
          margin-top: 5px;
          font-size: 0.8em;
        }
      }
    }
  }
}

#about-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: auto;
  //margin-top: 5%;
  position: relative;
  //border: 3px solid yellow;
  z-index: 4;
  overflow: hidden;

  #about-link{
    width: 100%;
    height: 0;
    margin-bottom: 0;
    margin-top: 100px;
  }

  hr {
    width: 100%;
    height: 0px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 100px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px dotted white;
    
  }

  #luna-logo {
    width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //margin-bottom: 10%;
    //border: 2px solid red;

    img {
      width: 100%;
      height: auto;
      //border: 2px solid red;
    }
  }

  #rice-bowl {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    width: 80vw;
    height: auto;
    overflow: hidden;
    //border: 2px solid red;
    position: relative;

    @media (max-width: $xxlarge) {
      width: 65vw;
    }

    @media (max-width: $large) {
      width: 90vw;
    }

    @media (max-width: $small) {
      width: 80vw;
    }

    #quote-about {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      @media (max-width: $small) {
        display: none;
      }

      //border: 2px solid yellow;

      h2 {
        width: 70%;
        font-family: $courier-text;
        font-size: 1.5em;
        color: $highlight;
        text-align: right;
        z-index: 1;

        @media (max-width: $large) {
          width: 90%;
          font-size: 1.2em;
          background-color: rgba(255, 255, 255, 0.8);
        }

        @media (max-width: $medium) {
          width: 90%;
          font-size: 1.2em;
        }
      }
      p {
        width: 70%;
        font-family: $font-fam;
        font-size: 1em;
        font-weight: normal;
        text-align: right;
        padding: 20px;
        color: rgb(0, 0, 0);
        margin-top: 0px;
      }
    }

    img {
      width: 1564px;

      height: auto;
      margin: 0;
      padding: 0;
      //border: 2px solid red;

      @media (max-width: $small) {
        transform: translateX(-100px);
      }
    }
  }

  

  #about-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    //border: 2px solid red;

    #about-background {
      width: 100%;
      position: absolute;
      z-index: -1;
      //border: 2px solid yellow;
      transform: translateY(-200px);

      @media (max-width: $small) {
        transform: translateY(-750px);
      }

      &::after {
        content: "";
        background: linear-gradient(
          to bottom,
          rgba($background, 1) 0%,
          transparent 50%,
          rgba($background, 1) 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
      }

      img {
        width: 100%;
        height: auto;
        //border: 2px solid red;
        //object-fit: cover;
        //object-position: top;
        display: block;

        @media (max-width: $small) {
          width: 180%;
        }
      }

      @media (max-width: $small) {
      }
    }

    #about-text {
      width: 100%;
      margin-right: 0%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 30px;
      text-indent: 10px;
      //border: 2px solid rgb(4, 0, 255);

      @media (max-width: $xxlarge) {
        width: 90vw;
      }

      @media (max-width: $medium) {
        width: 90vw;
        flex-direction: column;
      }
      @media (max-width: $small) {
        flex-direction: column;
      }

      #about-paragraph {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 5%;

        @media (max-width: $xlarge) {
          width: 90%;
          margin-right: 0%;
          margin-bottom: 10%;
        }
        @media (max-width: $medium) {
          width: 90%;
        }

        #sections {
          backdrop-filter: blur(4px);

          #section-1 {
            padding: 20px;
            border-right: 2px white solid;
            border-left: 2px white solid;
            border-top: 2px white solid;
            background-color: transparent;
            //backdrop-filter: blur(10px);

            @media (max-width: $large) {
              border-bottom: 2px white solid;
            }

            @media (max-width: $small) {
              border-bottom: 0px white solid;
            }
          }

          @media (max-width: $xlarge) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border-bottom: none;
          }

          @media (max-width: $small) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          #section-2 {
            border: 2px white solid;
            padding: 20px;

            @media (max-width: $xxlarge) {
              border-top: 0px white solid;
            }

            @media (max-width: $xlarge) {
              margin-left: 20px;
            }

            @media (max-width: $large) {
              border-top: 2px white solid;
            }
            @media (max-width: $small) {
              border-top: 0px white solid;
              margin-left: 0;
            }
            #jaime-about-1 {
              display: none;
              width: 100%;
              margin-top: 0px;

              @media (max-width: $xlarge) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              img {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 4px solid white;
                margin-bottom: 10px;
                &:hover {
                  border: 4px solid orange;
                }
              }
            }
          }
        }
        #about-poem {
          //background: radial-gradient(ellipse at center, rgba(103, 164, 228, 0.5) 0%, transparent 64.7%);
          border-radius: 10px;
          padding-top: 50px;
          padding-bottom: 50px;
          overflow: hidden;

          #poem-text {
            text-align: center;
            color: $highlight;
            background: linear-gradient(
              45deg,
              #a1c4fd,
              #c2e9fb,
              #a1c4fd,
              #c2e9fb
            );
            background-size: 200% 200%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: shine 4s linear infinite;
          }
        }

        @keyframes shine {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 100% 50%;
          }
        }

        h1 {
          font-family: $font-fam;
          font-size: 4em;
          text-align: center;
          padding: 10px;
          color: #ffffff;
        }

        h2 {
          font-size: 1em;
          font-weight: normal;
        }

        #paragraph {
          font-family: $courier-text;
          text-align: justify;
          color: white;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-top: 0px;
          font-size: 1.3em;
        }
      }

      #images {
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: $xlarge) {
          display: none;
        }
        //border: 2px solid red;

        img {
          width: 380px;
          height: auto;
        }

        #jaime-about {
          border: 4px solid white;
          &:hover {
            border: 4px solid orange;
          }
        }
      }

      .intro-text {
        background-color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        color: orange;
        font-size: 50px;

        &:hover {
          background-color: orange;
          color: white;
        }

        @media (max-width: $small) {
          text-align: center;
          font-size: 35px;
        }
      }
    }
  }

  #map {
    margin-top: 100px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-family: $font-fam;
      font-size: 3em;
      color: white;
      margin: 0;
      padding: 0;
      margin-bottom: 10px;
      text-align: center;
      @media (max-width: $medium) {
        font-size: 2em;
      }
    }
    p {
      font-family: $courier-text;
      font-size: 1.5em;
      color: $highlight;
      margin: 0;
      padding: 0;
      margin-top: 50px;
      text-align: center;
    }

    iframe {
      width: 70%;
      height: 50vh;
      border: 0px solid white;
      border-radius: 10px;
      margin: 0;
      padding: 0;

      @media (max-width: $large) {
        width: 90%;
      }
    }
  }

  #conclusion-paragraph {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
    position: relative;
    //background-color: $nav-background;

    #short-story-background {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //border: 2px solid red;

      img {
        width: 100%;
        height: auto;
        display: block;

        @media (max-width: $small) {
          display: none;
        }
      }
    }

    hr {
      width: 60vw;
      height: 0.5px;
      background-color: rgb(255, 255, 255);
      margin: 0;
      padding: 0;
    }

    h2 {
      font-family: $font-fam;
      font-size: 3em;
      margin: 0;
      margin-top: 20px;
      color: #ffffff;
      text-align: center;

      @media (max-width: $small) {
        margin-top: 40px;
        font-size: 2em;
      }
    }

    p {
      font-family: $courier-text;
      text-align: justify;
      font-size: 1.5em;
      font-weight: normal;
      width: 60%;
      height: 100%;
      padding: 20px;

      color: rgb(255, 255, 255);
      margin: 10px;

      @media (max-width: $small) {
        width: 90%;
        font-size: 1.5em;
      }
    }
  }

  #PictureRow {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0%;
    position: relative;

    #picture-row-background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      z-index: -1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //border: 2px solid red;

      @media (max-width: $small) {
        width: 200%;
      }

      &::after {
        content: "";
        background: linear-gradient(
          to bottom,
          rgba($background, 1) 0%,
          transparent 50%,
          rgba($background, 1) 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
      }
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    hr {
      width: 65%;
      height: 0.5px;
      background-color: rgb(255, 255, 255);
      margin: 0;
      padding: 0;
    }

    #quote {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        width: 100%;
        font-family: $courier-text;
        font-size: 3em;
        border: 0px dotted orange;
        color: white;
        z-index: 1;
        text-align: center;

        @media (max-width: $small) {
          font-size: 2.5em;
        }
      }
    }

    #picture-container {
      //margin-top: 10%;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      @media (max-width: $small) {
        flex-direction: column;
      }

      .picture-frame {
        width: 400px;
        height: 70%;
        margin: 0 0px;
        background-color: black;
        border: 6px solid rgb(229, 229, 229);
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        // transition: transform 0.5s ease-in-out;
        transition: width 0.5s ease-in-out;

        position: relative;

        @media (max-width: $small) {
          width: 90%;
          height: 400px;
          margin: 10px 0px;
        }

        &:hover {
          // transform: translate(-0px, -12px);
          width: 500px;
          border: 6px solid orange;
          transition: all 0.5s ease-in-out;

          @media (max-width: $small) {
            width: 50vw;
            padding-bottom: 200px;
          }

          .picture-text {
            opacity: 1;
          }
          img {
            opacity: 0.8;
            height: 105%;
            width: auto;

            @media (max-width: $small) {
              height: 130%;
              width: auto;
            }
          }
        }

        .picture-text {
          width: 100%;
          height: 20%;
          position: absolute;
          top: 70%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          opacity: 0;
          z-index: 100;
          background-color: orange;
          padding-bottom: 100px;

          @media (max-width: $small) {
            top: 50%;
            height: 50%;
          }

          hr {
            width: 90%;
            height: 2px;
            background-color: rgb(255, 255, 255);
            //smargin-bottom: 10px;
            margin-top: 10px;
            border: 0px solid white;

            @media (max-width: $small) {
              display: none;
            }
          }

          h2 {
            //width: 100%;
            font-family: $font-fam;
            font-size: 2em;
            padding: 5%;
            margin-top: 0%;
            margin-bottom: 0%;
            color: white;
            text-align: center;
            position: absolute;
            top: 0%;

            @media (max-width: $small) {
              font-size: 1.5em;
              margin-top: 10%;
              //border-bottom: 2px solid white;
              //border-right: 2px solid white;
              //border-left: 2px solid white;
            }
          }

          p {
            width: 90%;
            font-family: $courier-text;
            font-size: 1.3em;
            font-weight: normal;
            padding: 5px;

            color: white;
            text-align: center;
            position: absolute;
            top: 25%;
            font-family: $courier-text;

            @media (max-width: $small) {
              font-size: 1em;
              top: 20%;
              //border-bottom: 2px solid white;
              border-right: 2px solid white;
              border-left: 2px solid white;
            }
          }
        }

        img {
          width: auto;
          height: 100%;
          opacity: 1;

          @media (max-width: $small) {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

.section-title {
  width: 80vw;
  font-family: $font-fam;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: $nav-background;
  color: white;
  display: flex;
  justify-content: center;
  //border: 0px dotted rgb(155, 185, 255);

  @media (max-width: $large) {
    width: 90vw;
  }
  @media (max-width: $large) {
    width: 100vw;
  }

  @media (max-width: $small) {
    font-size: 1.2em;
  }

  &:hover {
    background-color: $nav-dark;
  }
}

#expertise-section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //margin-bottom: 10%;
  margin-top: 0;

  #expertise-container {
    width: 80vw;
    height: 100%;
    display: flex;

    @media (max-width: $medium) {
      flex-direction: column;
      width: 400px;
    }

    @media (max-width: $small) {
      width: 90vw;
    }

    .expertise-block {
      margin: 0 10px;
      width: 100%;
      height: 100%;

      @media (max-width: $medium) {
        margin: 0 0px;
      }

      h2 {
        font-size: 2.5em;
        font-family: $font-fam;
        background-color: rgb(255, 168, 47);
        padding-top: 10px;
        padding-bottom: 10px;
        color: white;
        text-indent: 10px;

        &:hover {
          background-color: rgb(226, 132, 0);
          color: rgb(250, 240, 222);
        }
      }

      p {
        font-size: 1.3em;
        font-family: $courier-text;
        text-align: justify;
        color: white;
      }

      .adjective-blue {
        color: $line-blue-color;
        background-color: $nav-background;
        display: flex;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 2.5em;
        color: rgb(255, 255, 255);
        text-indent: 10px;

        &:hover {
          background-color: $nav-dark;
          color: rgb(223, 226, 255);
        }
      }

      .adjective-orange {
        color: orange;
        background-color: rgb(231, 231, 231);
        display: flex;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 2.5em;
        text-indent: 10px;

        &:hover {
          background-color: orange;
          color: rgb(255, 255, 255);
        }
      }
    }
  }
  #expertise-footer {
    width: 80vw;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: 70%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.call-to-action {
  width: 150px;
  height: 50px;
  background-color: orange;
  border-style: none;
  border-radius: 10px;
}

#skills-section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8%;
  position: relative;

  #skills-container {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
    //border: 2px solid red;

    p {
      font-family: $courier-text;
      font-size: 1.5em;
      font-weight: normal;
      text-align: justify;
      color: white;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0px;
      width: 80%;
      height: auto;
      margin-bottom: 10px;
    }
  }
}

#contact {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0%;
  position: relative;
  //overflow: hidden;

  @media (max-width: $medium) {
    margin-top: 15%;
    margin-bottom: 15%;
  }
  

  #contact-back-container{

    width: 100vw;
    height: 100vh;

    #contact-background {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; 
      //border: 2px solid yellow;
      transform: translateY(-200px);
      overflow: hidden;
  
  
      &::after {
        content: "";
        background: linear-gradient(
          to bottom,
          rgba($background, 1) 0%,
          transparent 50%,
          rgba($background, 1) 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        
      }
  
      img {
        width: 100%;
        height: auto;
        //display: none;
      }
    }

  }
  

  h1 {
    font-family: $font-fam;
    font-size: 3em;
    color: white;
  }

  #contact-window {
    width: 64.7%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ///rgb(62, 93, 124);
    border-radius: 0px;
    border: 4px solid $line-blue-color;
    margin-bottom: 10%;
    margin-top: 0%;
    backdrop-filter: blur(5px);

    #submission-message{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 2em;
      color: white;
      font-family: $courier-text;
      background-color: grey;
      
    }

    @media (max-width: $large) {
      width: 80%;
    }

    @media (max-width: $medium) {
      width: 90%;
    }

    @media (max-width: $small) {
      width: 90%;
    }

    &:hover {
      border: 4px solid orange;
    }

    form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 50px;

      @media (max-width: $medium) {
        flex-direction: column;
      }

      label {
        font-family: $courier-text;
        font-size: 1.5em;
        font-weight: bold;
        margin: 10px;
        color: white;
      }

      #inputs {
        width: 50%;

        @media (max-width: $medium) {
          width: 90%;
        }

        .form-input {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          input {
            width: 80%;
            height: 50px;
            //border-radius: 10px;
            border: 2px solid rgb(119, 119, 119);
            margin: 10px;
            padding-left: 10px;
            font-family: $courier-text;
            font-size: 1.3em;
            font-weight: normal;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;

            &:focus {
              border: 4px solid orange;
            }
          }

          select {
            width: 80%;
            height: 50px;
            //border-radius: 10px;
            border: 2px solid rgb(119, 119, 119);
            margin: 10px;
            padding-left: 10px;
            font-family: $courier-text;
            font-size: 1.3em;
            font-weight: normal;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;

            &:focus {
              border: 4px solid orange;
            }
          }
        }
      }

      #message {
        width: 50%;

        @media (max-width: $medium) {
          width: 90%;
        }

        .form-input2 {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          textarea {
            width: 90%;
            height: 200px;
            //border-radius: 10px;
            border: 2px solid rgb(119, 119, 119);
            margin: 10px;
            padding-left: 10px;
            font-family: $courier-text;
            font-size: 1.3em;
            font-weight: normal;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;

            &:focus {
              border: 4px solid orange;
            }
          }

          button {
            width: 200px;
            height: 50px;
            background-color: orange;
            border: 0px solid white;
            //border-radius: 20px;
            font-family: $courier-text;
            font-size: 1.5em;
            font-weight: normal;
            color: white;
            margin-top: 10px;
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
              background-color: rgb(210, 136, 0);
              color: white;
            }
          }
        }
      }
    }
  }
  #contact-footer {
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 50%;
      height: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

#portfolio {
  .portfolio-blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
   

    #portfolio-background1 {
        width: 100vw;
        height: auto;
        //border-top: 2px solid white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        //overflow: hidden;
    
        @media (max-width: $small) {
          width: 100vw;
        }
    
        img {
          width: 100%;
          height: auto;
          //border: 2px solid red;
          display: block;
        }
    
        &::after {
          content: "";
          background: linear-gradient(
            to bottom,
            rgba($background, 1) 0%,
            transparent 50%,
            rgba($background, 1) 100%
          );
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
        }
      }

    #films-section{
        width: 100%;
        height: 0;
        margin-bottom: 60px;
        border: 0px solid white;
    }
    #podcasts-section{
        width: 100%;
        height: 0;
        margin-bottom: 60px;
        border: 0px solid white;
    }
    #music-section{
        width: 100%;
        height: 0;
        margin-bottom: 60px;
        border: 0px solid white;
    }

    #portfolio-sub-menu {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      opacity: 0.8;
      //border: 2px solid white;
      //background-color: rgba(0, 0, 0, 0.5);

      &:hover{
        opacity: 1;
      }

      ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        //align-items: center;
        list-style-type: none;
        //border: 2px solid rgb(130, 135, 42);
        padding: 0;

        li {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: stretch;
          align-items: center;
          list-style-type: none;
          cursor: pointer;
          //border-right: 2px solid rgb(255, 255, 255);
          background-color: rgb(255, 170, 0);
          border-top: 2px solid white;

          &:hover {
            background-color: white;
          }

          a {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            list-style-type: none;
            cursor: pointer;
            text-decoration: none;
            color: rgb(255, 255, 255);
            font-family: $courier-text;
            font-size: 1.5em;
            font-weight: normal;
            text-align: center;

            &:hover {
              color: orange;
            }
          }
        }
      }
    }

    #portfolio-title {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      margin-bottom: 10px;

      p {
        width: 80%;
        font-size: 1.6em;
        font-family: $courier-text;
        font-weight: normal;
        color: white;
        margin: 50px;
        padding: 0;
      }

      h1 {
        font-size: 5em;
        font-family: $font-fam;
        color: white;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;

        @media (max-width: $medium) {
          font-size: 3em;
        }
      }

      hr {
        width: 90%;
        height: 0.5px;
        background-color: rgb(255, 255, 255);
        margin: 0;
        padding: 0;
      }
    }

    .portfolio-column {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;

      @media (max-width: $small) {
        flex-direction: column;
      }

      #podcast-stuff {
        display: flex;
        flex-direction: column;
        
      }

      #podcast-window {
        //border: 2px solid rgb(255, 255, 255);
        width: auto;
        height: auto;
        margin-bottom: 100px;
        

        iframe {
          width: 400px;
          height: 600px;
          border: 0px solid white;
          border-radius: 10px;
          margin: 0;
          padding: 0;
        }
      }

      .review-quotes {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
        font-family: "Arial", sans-serif;

        #imagined-quotes {
          width: 50%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 1.5em;
            font-family: $courier-text;
            font-weight: normal;
            color: white;
            margin: 50px;
            padding: 0;
            text-align: center;
          }
        }

        .quote {
          background-color: $nav-background;
          border-left: 4px solid #0e3c6e;
          margin: 10px 0;
          padding: 10px 20px;
          position: relative;
          width: 80%;

          p {
            font-size: 16px;
            color: white;
            margin: 0;
          }

          cite {
            font-size: 14px;
            color: #666;
            display: block;
            margin-top: 8px;
            text-align: right;
            font-style: normal;
          }

          &:before {
            content: open-quote;
            font-size: 50px;
            position: absolute;
            left: 10px;
            top: -10px;
            color: #007bff;
          }

          &:after {
            content: close-quote;
            font-size: 50px;
            position: absolute;
            right: 10px;
            bottom: -10px;
            color: #007bff;
          }
        }
      }
    }

    #album-list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: auto;
      position: relative;
      overflow: hidden;

      @media (max-width: $large) {
        flex-direction: column;
        align-items: center;
      }

      @media (max-width: $small) {
        width: 100vw;
        flex-direction: column;
        align-items: center;
      }
      
      #portfolio-background3 {
        width: 100vw;
        height: auto;
        //border-top: 2px solid white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        //overflow: hidden;
        
    
        @media (max-width: $small) {
          width: 100vw;
        }
    
        img {
          width: 100%;
          height: auto;
          //border: 2px solid red;
          display: block;
        }
    
        &::after {
          content: "";
          background: linear-gradient(
            to bottom,
            rgba($background, 1) 0%,
            transparent 50%,
            rgba($background, 1) 100%
          );
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
        }
      }

      .albums {
        margin: 10px;
        width: 33.3%;

        @media (max-width: $large) {
          width: 100%;
        }

        iframe{
            width: 100%;
            height: 100%;
            border: 0px solid white;
            border-radius: 10px;
            margin: 0;
            padding: 0;
            
        }

        
      }
    }
  }
  .intro-portfolio {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px;
    position: relative;

   

    p {
      width: 50%;
      font-size: 1.3em;
      font-family: $courier-text;
      font-weight: normal;
      color: white;
      margin: 0px;
      padding: 50px;
      border: 2px rgb(167, 167, 167) solid;
      text-align: justify;

      @media (max-width: $small) {
        width: 70%;
        font-size: 1.5em;
      }

      &:hover{
        border: 2px solid orange;
      }
    }
  }
    
  #podcasts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    //background-color: white;
    margin-bottom: 60px;
  }
  #portfolio-background2 {
    width: 100vw;
    height: auto;
    //border-top: 2px solid white;
    position: absolute;
    top: -700px;
    left: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

    @media (max-width: $xxlarge) {
      top: -300px;
    }

    @media (max-width: $xlarge) {
      top: -0px;
    }

    @media (max-width: $small) {
      width: 100vw;
    }

    img {
      width: 100%;
      height: auto;
      //border: 2px solid red;
      
    }

    &::after {
      content: "";
      background: linear-gradient(
        to bottom,
        rgba($background, 1) 0%,
        rgba(transparent, 0.5) 50%,
        rgba($background, 1) 80%
      );
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  }

}
#audio-container {
  width: 30%;
  height: auto;
  //background-color: rgb(9, 6, 37);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px;
  //border: 2px solid orange;

  @media (max-width: $medium) {
    margin: 20px;
    width: 80%;
  }

  #movie-poster {
    width: 100%;
    height: auto;
    margin: 0;
    position: relative;
    //overflow: hidden;

    img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      border-radius: 0%;
      border: 3px solid rgb(255, 255, 255);
      position: relative;
      display: inline-block;
      filter: grayscale(100%);

      &:hover {
        border: 3px solid orange;
        filter: grayscale(0%);
      }
    }
  }

  #audio-player {
    margin: 0;
    width: 100%;
    height: auto;
    //background-color: #5f5f5f;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    //overflow: scroll;

    .track-list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      h2 {
        font-size: 1.5em;
        font-weight: bold;
        font-family: $font-fam;
        margin-top: 0px;
        padding: 10px;
        color: white;
        margin-bottom: 10px;
        background-color: $nav-background;
        width: 98%;
        text-align: center;
      }

      hr {
        width: 100%;
        height: 5px;
        background-color: rgb(255, 255, 255);
        margin: 0 0 5px 0;
        padding: 0;
        border: 2px solid rgb(255, 255, 255);
        display: none;
      }

      .track {
        width: 100%;
        height: 50px;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 5px;
        border: 1px solid white;
        //border-radius: 5px;

        &:hover {
          background-color: rgb(255, 162, 0);
          width: 105%;
          height: 55px;

          i {
            color: rgb(255, 255, 255);
          }
        }

        i {
          color: rgb(255, 255, 255);
          font-size: 1.5em;
          margin: 5px;
          padding: 0;
          cursor: pointer;
          height: 100%;
          width: auto;
        }

        .audio-controls {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 0px solid black;

          #playback-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: auto;
            height: 100%;
            border: 0px solid black;
          }

          h5 {
            font-size: 1em;
            width: 90%;
            font-weight: bold;
            margin-left: 20px;
            margin-right: 20px;
            padding: 0;
            color: rgb(255, 255, 255);
            font-family: $courier-text;
            border: 2px solid rgb(255, 255, 255);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;

            @media (max-width: $small) {
              font-size: 0.8em;
            }
          }

          button {
            height: 100%;
            margin: 5px;
            cursor: pointer;
            border: 0px solid black;
            border-radius: 0%;
            background-color: transparent;

            &:hover {
              background-color: transparent;

              i {
                color: $highlight;
                transform: scale(1.3);
              }
            }
          }
        }

        .track-info {
          width: 10%;
          height: auto;
          background-color: transparent;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: right;
          //margin-left: 20px;
          margin-right: 0;
          font-family: $courier-text;
          color: orange;

          .track-number {
            background-color: rgb(208, 208, 208);
            color: rgb(0, 0, 0);
            width: auto;
            height: 100%;
            border-radius: 0%;
            border: 2px solid black;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5em;
            font-weight: bold;
            margin: 0;
            margin-left: 10px;
            padding: 0;
          }

          .playing {
            animation: blinkingEffect 1s steps(1, end) infinite;
          }
        }
      }
    }
  }
}

@keyframes blinkingEffect {
  0% {
    background-color: rgb(40, 63, 87);
  } /* Start with base color (change as needed) */
  50% {
    background-color: rgb(103, 164, 224);
  } /* Halfway through the animation switch to different color (change as needed) */
  100% {
    background-color: rgb(40, 63, 87);
  } /* Finish the animation cycle back to base color */
}

#LogoScroller {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background-color: rgb(255, 136, 0);
  position: relative;

  .triangle {
    width: 0;
    height: 0;
    border-left: 10vw solid transparent;
    border-right: 10vw solid transparent;
    border-bottom: 20vw solid transparent;
    position: absolute;
    transform-origin: 50% 50%;
    margin: 500px;

    @media (max-width: $medium) {
      display: none;
    }
  }

  #logo-container {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    //border: 2px solid rgb(0, 238, 255);

    img {
      width: auto;
      height: 5vw;
      margin: 20px;
      filter: grayscale(100%);
      transition: filter 0.3s ease;

      &:hover {
        filter: grayscale(0%);
      }

      @media (max-width: $large) {
        //height: 20%;
        margin: 10px;
      }

      @media (max-width: $medium) {
        height: 50px;
        margin: 10px;
      }
    }

    .logo {
      &:hover {
        transform: scale(1.2);
      }
    }

    #FirstLogoGroup {
      width: 98vw;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 10px;
      //border: 2px solid red;

      @media (max-width: $medium) {
        flex-direction: column;
      }

      .row-group {
        flex-direction: row;
        //border: 2px solid rgb(255, 0, 0);
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        margin: 0px 0px 0px 0px;

        .logo {
          margin: 0px 0px 0px 0px;
        }
      }
    }

    #ColumnLogoGroup {
      width: 90vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      //sborder: 2px solid yellow;

      @media (max-width: $medium) {
        flex-direction: column;
        width: 100%;
      }

      #SecondLogoGroup {
        //margin-right: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-items: center;
        //border: 2px solid blue;

        @media (max-width: $medium) {
          flex-direction: row;
          margin-right: 0;
          justify-content: space-around;
          width: 100%;
        }
      }
      #ThirdLogoGroup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //border: 2px solid green;

        @media (max-width: $medium) {
          margin: 0;
          flex-direction: row;
          justify-content: space-around;
          width: 100%;
        }
      }
    }

    #FourthLogoGroup {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      //border: 2px solid purple;
      width: 100%;

      @media (max-width: $medium) {
        flex-direction: column;
        width: 100%;
      }
      .row-group {
        flex-direction: row;
        //border: 2px solid rgb(255, 0, 0);
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;

        .logo {
          margin-left: 0px;
        }
      }
    }
  }
}

#services {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //border: 2px solid orange;

  h2 {
    font-size: 3em;
    font-family: $font-fam;
    color: orange;
    margin: 10px 0 10px 0;
    padding: 0;
    text-align: center;

    @media (max-width: $medium) {
      font-size: 2em;
    }
  }

  #services-container {
    width: 80vw;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: $large) {
      width: 90vw;
    }
    @media (max-width: $medium) {
      width: 80vw;
    }

    @media (max-width: $small) {
      width: 90vw;
    }

    hr {
      width: 90%;
      height: 0.5px;
      background-color: rgb(255, 255, 255);
      margin: 0;
      padding: 0;
    }

    @media (max-width: $medium) {
      flex-direction: column;
    }

    .services-block {
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 4px solid rgb(103, 103, 103);
      margin: 15px;
      padding-bottom: 20px;

      &:hover {
        border: 4px solid orange;
      }

      h1 {
        font-size: 2em;
        font-family: $font-fam;
        padding-top: 10px;
        padding-bottom: 10px;
        padding: 10px;
        border-radius: 10px;
        color: white;
        text-align: center;
      }

      p {
        font-size: 1.5em;
        font-family: $courier-text;
        text-align: justify;
        margin: 20px;
        color: white;
      }
    }
  }

  #other-services {
    margin-top: 0;
    width: 90vw;
    height: auto;
    background-color: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;

    //border: 2px solid red;

    @media (max-width: $medium) {
      flex-direction: column;
    }
    .other-services-block {
      width: 35vw;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 15px;
      padding: 0;
      border: 4px solid rgb(114, 114, 114);
      background-color: none;
      padding-bottom: 10px;

      &:hover {
        border: 4px solid orange;
      }

      @media (max-width: $large) {
        width: 90vw;
      }
      @media (max-width: $medium) {
        width: 75vw;
      }

      h1 {
        font-size: 2.5em;
        font-family: $font-fam;
        color: rgb(255, 255, 255);
        padding-top: 10px;
        margin: 0 10px 0px 10px;
        text-align: center;

        @media (max-width: $large) {
          font-size: 2em;
        }
      }

      hr {
        width: 90%;
        height: 0.5px;
        background-color: rgb(255, 255, 255);
        margin: 0;
        padding: 0;
      }

      p {
        color: white;
        font-size: 1.3em;
        font-family: $courier-text;
        text-align: center;
        margin: 10px;
      }
    }
  }
}

#button-cta {
  width: 200px;
  height: 50px;
  background-color: orange;
  //border-radius: 50px;
  font-family: $font-fam;
  font-size: 1em;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgb(255, 200, 99);
  }

  a {
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    background-color: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: orange;

      #greater-than {
        transform: translateX(5px);
      }
    }
  }
  #greater-than {
    display: flex;
    justify-content: center;
    align-items: center;
    //border: 2px solid white;
    //border-radius: 100%;
    font-size: 2em;
    width: 30px;
    height: 30px;
    position: relative;
    transition: transform 0.3s ease;

    p {
      margin: 0;
      padding: 0;
    }
  }
}

/* Podcast Player Container */
#podcast-player {
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Episode List */
  #episode-list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;

    button {
      display: block;
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      text-align: left;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #eee;
      }
    }
  }

  #audio-control {
    width: 100%;
    height: 40px;
  }
}

/* Footer.scss */

.footer {
  background-color: $background; // Dark background
  color: white; // Light text
  text-align: center;
  font-family: $courier-text;
  padding: 0 0 100px 0;

  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100vw;
    //margin: 0 auto;
    //padding: 20px;

    .social-media {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      width: 100%;

      svg{
        width: 20px;
        height: auto;
        margin-right: 10px;
        padding: 0;
        color: white;
        transform: translateY(5px);

        path{
          fill: white;
        }
        
      }
    }

    .quick-links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    div {
      margin: 10px;
      flex: 1;
      min-width: 200px;
    }

    h4 {
      margin-bottom: 15px;
      font-size: 1.2em;
      color: #f8f8f8; // Slightly lighter text for headings
      font-family: $font-fam;
    }

    p,
    a {
      color: #ddd; // Slightly dimmer text for links and paragraphs
      text-decoration: none;
      line-height: 1.6;

      &:hover {
        color: #fff; // Change color on hover
        text-decoration: underline;
      }
    }
  }

  .legal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#terms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;

  hr{
    width: 500px;
    height: 0.5px;
    background-color: rgb(255, 255, 255);
    margin: 100px;
    padding: 0;

    @media (max-width: $small) {
      width: 90%;
    }
  }
  

  section{
    width: 500px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: 2px solid red;
    margin: 10px;
    padding: 0;
    font-family: $courier-text;
    color: white;
    margin: 10px;
    padding: 0;
    text-align: center;

    @media (max-width: $small) {
      width: 90%;
    }
  }

  #policy-section{
    width: 500px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: 2px solid red;
    margin: 10px;
    padding: 0;
    font-family: $courier-text;
    color: white;
    margin: 10px;
    padding: 0;
    text-align: center;

    @media (max-width: $small) {
      width: 90%;
    }

  }
  h2{
    font-size: 3em;
    font-family: $font-fam;
    color: orange;
    margin: 10px 0 10px 0;
    padding: 0;
    text-align: center;

    @media (max-width: $medium) {
      font-size: 2em;
    }
  }

  h4{
    font-size: 1.5em;
    font-family: $font-fam;
    color: orange;
    margin: 10px 0 10px 0;
    padding: 0;
    text-align: center;
  }
  h3{
    font-size: 2em;
    font-family: $font-fam;
    color: orange;
    margin: 10px 0 10px 0;
    padding: 0;
    text-align: center;

    @media (max-width: $medium) {
      font-size: 1.5em;
    }
  }

  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: 2px solid red;

    li{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px;
      padding: 0;
      //border: 2px solid blue;
      font-size: 1.2em;
        font-family: $courier-text;
        color: white;
        margin: 10px;
        padding: 0;
        text-align: justify;
      
    }
  }

  p{
    font-size: 1.2em;
        font-family: $courier-text;
        color: white;
        margin: 10px;
        padding: 0;
        text-align: justify;
  }
}

.image-gallery {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0px 100px 0px;
  position: relative;
  overflow: hidden;

  @media (max-width: $small) {
    width: 100vw;
    margin: 0px;
  }
    //radial-gradient(ellipse at bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
  

  #gallery-background {
    width: 100%;
    height: auto;
    display: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -2;

    @media (max-width: $small) {
      height: 200%;
    }
  }

  &::after{
    content: "";
    background: linear-gradient(
            to bottom,
            rgba($background, 1) 0%,
            transparent 50%,
            rgba($background, 1) 100%
          );
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
  }

  .left-arrow{
    position: absolute;
    z-index: 2;
    left: 20%;
    justify-content: flex-start;
    @media (max-width: $medium){
      left: 2%;
    }
    @media (max-width: $small){
      left: 0%;
    }
  }
  .right-arrow{
    position: absolute;
    z-index: 2;
    right: 20%;
    justify-content: flex-end;
    @media (max-width: $medium){
      right: 2%;
    }
    @media (max-width: $small){
      right: 0%;
    }
  }

  .left-arrow, .right-arrow {
    font-size: 4em;
    color: orange; // Assuming $highlight is a color variable defined in your styles
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    border-radius: 100%; 
    //border: 2px solid $highlight;
    height: 70px;
    width: 70px;
    //background-color: orange;
    //box-shadow: 0px 0px 100px black;

    &:hover {
      color: white;
    }
  }

  .image-container {
    width: 400px;
    height: 400px;
    margin: 20px 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; // To ensure images don't overflow their container
    border: 4px solid white;
    filter: grayscale(100%);
    transition: filter 0.3s ease, border 0.3s ease;
    box-shadow: 0px 0px 100px black;
    z-index: 0;
    //background-color: black;s
    
    @media (max-width: $medium){
      width: 70%;
    }

    &:hover{
      border: 4px solid orange;
      filter: grayscale(0%);
    }

    img {
      width: auto;
      height: 100%;
      transition: transform 0.3s ease-out;
      background-color: transparent;
      object-fit: cover;
      &:hover {
        transform: scale(1.05); 
      }
    }
  }
}
