@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans&display=swap');


$background: #2c2c2c;
$text: #000000; 
//$nav-background: rgb(64, 76, 101);
$nav-background: rgb(48, 64, 82);
$nav-dark: rgb(33, 49, 66);
/* $font-fam: 'Inconsolata', monospace; */
$font-fam: 'Yeseva One', serif;
$line-blue-color: rgb(102, 102, 102);
$courier-text: 'Fira Sans Extra Condensed', sans-serif;
$highlight: rgb(103, 164, 228);


//Screen Sizes

$xsmall: 475px;
$small: 640px;
$medium: 768px;
$large: 1024px;
$xlarge: 1280px;
$xxlarge: 2560px;


